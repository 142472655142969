import { useCallback } from "react";
import { useTranslation } from "react-i18next";

const useGetTranslatedCredentialType = () => {
    const { t } = useTranslation();

    const translatedCredential = t("certificate_type.credential");
    const translatedBadge = t("certificate_type.badge");
    const translatedCertificate = t("certificate_type.certificate");

    const getTranslatedCredentialType = useCallback(
        (
            credentialType: "badge" | "generic" | "certificate" | "event",
            options?: {
                upperCase?: boolean;
                firstLetterUpperCase?: boolean;
            }
        ) => {
            let value = translatedCredential;
            switch (credentialType) {
                case "certificate":
                    value = translatedCertificate;
                    break;
                case "badge":
                    value = translatedBadge;
                    break;
                default:
                    break;
            }

            if (options?.upperCase) {
                value = value.toUpperCase();
            }

            if (options?.firstLetterUpperCase) {
                value = value.charAt(0).toUpperCase() + value.slice(1);
            }

            return value;
        },
        [translatedBadge, translatedCertificate, translatedCredential]
    );

    return getTranslatedCredentialType;
};

export default useGetTranslatedCredentialType;
