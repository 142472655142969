import { SvgIcon, SvgIconProps } from "@material-ui/core";

const UnverifiedIssuerIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox="0 0 16 20">
            <path
                d="M7.99998 13.8334C8.27151 13.8334 8.49911 13.7415 8.68279 13.5578C8.86647 13.3742 8.95831 13.1465 8.95831 12.875C8.95831 12.6035 8.86647 12.3759 8.68279 12.1922C8.49911 12.0085 8.27151 11.9167 7.99998 11.9167C7.72845 11.9167 7.50085 12.0085 7.31717 12.1922C7.13349 12.3759 7.04165 12.6035 7.04165 12.875C7.04165 13.1465 7.13349 13.3742 7.31717 13.5578C7.50085 13.7415 7.72845 13.8334 7.99998 13.8334ZM7.04165 10H8.95831V5.20835H7.04165V10ZM7.99998 19.5834C5.77984 19.0243 3.94703 17.7505 2.50154 15.762C1.05606 13.7735 0.333313 11.5653 0.333313 9.13752V3.29169L7.99998 0.416687L15.6666 3.29169V9.13752C15.6666 11.5653 14.9439 13.7735 13.4984 15.762C12.0529 17.7505 10.2201 19.0243 7.99998 19.5834ZM7.99998 17.5709C9.66109 17.0438 11.0347 15.9896 12.1208 14.4084C13.2069 12.8271 13.75 11.0702 13.75 9.13752V4.6094L7.99998 2.45315L2.24998 4.6094V9.13752C2.24998 11.0702 2.79304 12.8271 3.87915 14.4084C4.96526 15.9896 6.33887 17.0438 7.99998 17.5709Z"
                fill="white"
            />
        </SvgIcon>
    );
};

export default UnverifiedIssuerIcon;
