/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import { useState, useEffect } from "react";
import clsx from "clsx";
import axios from "axios";
import { Trans, useTranslation } from "react-i18next";

// Utils
import { getCookie, validateEmail } from "../../../utils/utils";
import { getBackendUrl } from "../../../utils/routes";

// MUI
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Checkbox, Typography } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CircularProgress from "@material-ui/core/CircularProgress";

// Redux
import { createMessage } from "../../../actions/messages";

// Spinner
import LoadingOverlay from "../../Common/LoadingOverlay";

// Images
import linkedinCertDummy from "../../../Assets/Images/Apps/LinkedinCertificateLinkGenerator/linkedin-cert-dummy.svg";
import linkedinCertDummyDE from "../../../Assets/Images/Apps/LinkedinCertificateLinkGenerator/linkedin-cert-dummy_ger.svg";

// GA
import { TContactPostObject } from "./linkedInCertificateLinkGenerator.types";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "../../../redux/hooks";
import LinkTranslated from "../../Common/LinkTranslated";
// gtm
import TagManager from "react-gtm-module";
// GA
import ReactGA from "react-ga4";
import { set } from "lodash";
import { is } from "date-fns/locale";

// Init Google Analytics
ReactGA.initialize("G-PQD1GED98Z", {
    gaOptions: {
        debug_mode: true,
        siteSpeedSampleRate: 100,
    },
});

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundColor: "white",
        left: 0,
        top: 0,
        position: "absolute",
        width: "100vw",
        height: "100vh",
        padding: 50,
    },
    h1: {
        textAlign: "center",
        fontWeight: 500,
    },
    h2: {
        textAlign: "center",
        fontSize: "1.1rem",
        fontWeight: 400,
        color: "grey",
        marginTop: 30,
    },
    h3: {
        fontSize: "1.3rem",
        marginBottom: 20,
    },
    dummyImage: {
        borderRadius: 4,
        boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
        width: "100%",
        // marginLeft: "25%",
    },
    containerWrap: {
        display: "flex",
        justifyContent: "center",
        marginTop: 50,
    },
    form: {
        backgroundColor: "white",
        boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
        border: "solid 1px lightgray",
        borderRadius: 8,
        display: "flex",
        flexDirection: "column",
        width: "100%",
        padding: 50,
    },
    input: {
        marginBottom: 15,
    },
    output: {
        minHeight: 100,
        backgroundColor: "white",
        border: "solid 1px lightgray",
        borderRadius: 8,
        boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
        width: "70%",
        marginLeft: "15%",
        padding: 30,
        marginTop: 50,
    },
    linkContainer: {
        border: "solid 1px lightgray",
        borderRadius: 8,
        minHeight: 50,
        padding: 20,
    },
    margin10: {
        height: 10,
    },
    margin20: {
        height: 20,
    },
    divider: {
        marginTop: 30,
        marginBottom: 30,
        borderBottom: "solid 1px lightgrey",
        width: "80%",
        marginLeft: "10%",
    },
    show: {
        display: "block",
    },
    hide: {
        display: "none",
    },
    forceSelect: {
        WebkitUserSelect: "auto",
        MozUserSelect: "auto",
        MsUserSelect: "auto",
        userSelect: "auto",
    },
    label: {
        display: "flex",
        backgroundColor: "red",
    },
    typography: {
        "&.MuiTypography-body1": {
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            marginBottom: "0px",
            lineHeight: "0px",
        },
    },
}));

interface Props {
    privateRoute?: boolean;
}

const LinkedinCertificateLinkGenerator = ({ privateRoute }: Props) => {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { t, i18n } = useTranslation();
    const classes = useStyles();

    const [certName, setCertName] = useState("");
    const [orgaId, setOrgaId] = useState("");
    const [issueYear, setIssueYear] = useState("");
    const [certLink, setCertLink] = useState("");
    const [showSpinner, setShowSpinner] = useState(false);
    const [clickCount, setClickCount] = useState(getCookie("clickCount") || "0");
    const [leadFirstName, setLeadFirstName] = useState("");
    const [leadLastName, setLeadLastName] = useState("");
    const [leadEmail, setLeadEmail] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);

    const currentYear = Number(new Date().getFullYear());
    const env: TEnv = ["localhost", "127.0.0.1"].includes(window.location.hostname) ? "local" : "prod";

    const updateLink = () => {
        const issueDateIsValid =
            Number(issueYear || 0) <= currentYear && Number(issueYear || 0) > new Date(0).getFullYear();
        setCertLink("");

        if (certName === "") {
            dispatch(createMessage(t("linkedin_certificate_generator.messages.please_add_a_certificate_name"), "info"));
            return;
        }

        if (issueYear.length === 0 || issueDateIsValid) {
            setShowSpinner(true);
            const clickCalc = Number(clickCount) + 1;
            setClickCount(String(clickCalc));
            let isID = !isNaN(parseInt(orgaId));

            setTimeout(() => {
                setShowSpinner(false);
                setCertLink(
                    `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${certName}&${
                        isID ? "organizationId" : "organizationName"
                    }=${orgaId}&issueYear=${issueYear}`
                );
            }, 1000);
            return;
        }
        if (issueYear.length < 4 || !issueDateIsValid) {
            dispatch(
                createMessage(t("linkedin_certificate_generator.messages.please_enter_a_valid_issue_date"), "error")
            );
            return;
        }

        // Check if orgaid is a name or id (can be both)
        // To achieve this we parse the string to int
        // If the value is NaN we know it was not a number/id
        // Therefore we need to negate the whole expression
        // to find out wheter it is and ID or not.
    };

    const buttonClick = async (type: "capture-lead" | "sign-up") => {
        console.log("isChecked", isChecked);
        setSubmitLoading(true);

        if (!leadFirstName || !leadLastName) {
            dispatch(
                createMessage(
                    t(
                        "linkedin_certificate_generator.messages.please_enter_a_name",
                        "Please enter a valid first and last name"
                    ),
                    "error"
                )
            );
            setSubmitLoading(false);
            return;
        }
        if (leadEmail === "" || !validateEmail(leadEmail)) {
            dispatch(
                createMessage(t("linkedin_certificate_generator.messages.please_enter_a_valid_email_address"), "error")
            );
            setSubmitLoading(false);
            return;
        }
        if (!isChecked) {
            dispatch(
                createMessage(
                    t("linkedin_certificate_generator.messages.please_agree_to_the_terms_of_use_and_privacy_policy"),
                    "error"
                )
            );
            setSubmitLoading(false);
            return;
        }

        // Add contact to crm
        var contact: TContactPostObject = {};
        contact.email = leadEmail;
        contact.first_name = leadFirstName;
        contact.last_name = leadLastName;

        let API_URL = getBackendUrl(env, "virtualbadge");

        axios.post(`${API_URL}/api/public/put_lead_into_crm`, {
            ...contact,
            identifier: "VB-CV1LP1ALL-9999",
        });

        if (type === "sign-up") {
            await hsFormSubmission();
            await signup();
            setSubmitLoading(false);
            return;
        }

        document.cookie = "leadCompleted=true; path=/;";
        setClickCount(clickCount + 1);
        dispatch(
            createMessage(
                t("linkedin_certificate_generator.messages.you_can_now_generate_as_many_certificates_as_you_wish"),
                "success"
            )
        );
        setSubmitLoading(false);
    };

    async function generateRandomPassword(length: number) {
        const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=";
        let password = "";

        for (let i = 0; i < length; i++) {
            // Picks a random character from the chars string
            let randomIndex = Math.floor(Math.random() * chars.length);
            password += chars[randomIndex];
        }

        // Make sure the password passes the requirements
        password += "!Aa1";

        return password;
    }

    const signup = async () => {
        axios
            .post(
                `${getBackendUrl(env, "virtualbadge")}/api/auth/register/user?redirect=true`,
                {
                    user: {
                        first_name: leadFirstName,
                        last_name: leadLastName,
                        email: leadEmail,
                        password: await generateRandomPassword(20),
                    },
                    orga: {
                        name: isNaN(parseInt(orgaId)) ? orgaId : "Your Organization",
                        industry: "",
                    },
                    duration: 99999,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((res) => {
                const redirectLink = res.data.redirectLink.replace("plans", "activate-account");
                // If the window is in an iframe, redirect the parent window
                if (window.self !== window.top) window.parent.location = redirectLink;
                else window.parent.location = redirectLink;
            })
            .catch((err) => {
                if (err.response.status === 409) {
                    dispatch(
                        createMessage(
                            t(
                                "linkedin_certificate_generator.messages.user_exists",
                                "This email is already registered"
                            ),
                            "info"
                        )
                    );
                }
            });
    };

    const hsFormSubmission = async () => {
        const HSPortalId = "139765941";
        const HSFormId = "9928d264-9905-4e70-ab13-cc4dc61bc881";
        const HSFormSubmissionAPI = `https://api.hsforms.com/submissions/v3/integration/submit/${HSPortalId}/${HSFormId}`;
        await axios.post(HSFormSubmissionAPI, {
            submittedAt: Date.now(),
            context: {
                pageUri: window.location.href,
                pageName: document.title,
                hutk: getCookie("hubspotutk"),
            },
            consent: {
                legitimateInterest: {
                    value: true,
                    // This must be true when using the 'legitimateInterest' option, as it reflects the consent indicated by the visitor when submitting the form
                    subscriptionTypeId: "192643898",
                    // Integer; The ID of the specific subscription type that this forms indicates interest to.
                    legalBasis: "LEAD",
                    // String, one of CUSTOMER or LEAD; Whether this form indicates legitimate interest from a prospect/lead or from a client.
                    text: 'I accept the <a href="https://www.virtualbadge.io/terms" rel="noopener">Terms of Use</a> and <a href="https://www.virtualbadge.io/privacy-policy" rel="noopener">Privacy Policy</a> of Virtualbadge.io',
                    // String; The privacy text displayed to the visitor.
                },
            },
            fields: [
                {
                    objectTypeId: "0-1",
                    name: "firstname",
                    value: leadFirstName,
                },
                {
                    objectTypeId: "0-1",
                    name: "lastname",
                    value: leadLastName,
                },
                {
                    objectTypeId: "0-1",
                    name: "email",
                    value: leadEmail,
                },
                {
                    objectTypeId: "0-1",
                    name: "hs_legal_basis",
                    value: "Legitimate interest – prospect/lead",
                },
            ],
        });
    };

    useEffect(() => {
        if (process.env.REACT_APP_ENV === "prod") {
            TagManager.initialize({ gtmId: "GTM-NGH9XKL" });
            const isDeLanguage: boolean = location.pathname.includes("/de/");
            i18n.changeLanguage(isDeLanguage ? "de" : "en");
        } else i18n.changeLanguage(localStorage.getItem("lng") || "en");

        // This useEffect should only run initial.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        document.cookie = `clickCount=${parseInt(clickCount)}; path=/;`;
    }, [clickCount]);

    return (
        <div className={classes.background}>
            {/* <h1 className={classes.h1}>LinkedIn Certificate Link Generator</h1> */}
            <h2 className={classes.h2}>{t("linkedin_certificate_generator.texts.example", "Example:")}</h2>
            <img
                className={classes.dummyImage}
                src={i18n.language === "de" ? linkedinCertDummyDE : linkedinCertDummy}
            />
            {!getCookie("leadCompleted") && Number(clickCount) === 1 && !privateRoute ? (
                <div className={classes.containerWrap}>
                    <div className={classes.form}>
                        <h3 className={clsx(classes.h3, "text-center")}>
                            {t("linkedin_certificate_generator.texts.gain_access", "Gain access")}
                        </h3>
                        <p className="text-muted text-center">
                            {t(
                                "linkedin_certificate_generator.texts.create_cert",
                                "Create your free LinkedIn certificate"
                            )}
                        </p>
                        {/* Prevent focus on first text field. TODO: do this properly */}
                        <TextField style={{ display: "none" }} />

                        <TextField
                            className={classes.input}
                            variant="outlined"
                            size="small"
                            label={t("linkedin_certificate_generator.labels.first_name")}
                            required
                            value={leadFirstName}
                            onChange={(e) => {
                                setLeadFirstName(e.target.value);
                            }}
                        />
                        <TextField
                            className={classes.input}
                            variant="outlined"
                            size="small"
                            label={t("linkedin_certificate_generator.labels.last_name")}
                            required
                            value={leadLastName}
                            onChange={(e) => {
                                setLeadLastName(e.target.value);
                            }}
                        />
                        <TextField
                            className={classes.input}
                            variant="outlined"
                            size="small"
                            type="email"
                            label={t("linkedin_certificate_generator.labels.email")}
                            required
                            value={leadEmail}
                            onChange={(e) => {
                                setLeadEmail(e.target.value);
                            }}
                        />
                        <FormControlLabel
                            style={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "row",
                                flexWrap: "nowrap",
                            }}
                            control={
                                <Checkbox
                                    value={isChecked}
                                    onChange={() => setIsChecked((prev) => !prev)}
                                    color="primary"
                                />
                            }
                            label={
                                <Typography className={classes.typography}>
                                    <Trans
                                        t={t}
                                        i18nKey="linkedin_certificate_generator.labels.consent_terms"
                                        values={{
                                            terms: t("linkedin_certificate_generator.labels.terms"),
                                            privacy: t("linkedin_certificate_generator.labels.privacy"),
                                        }}
                                        components={{
                                            terms_component: (
                                                <LinkTranslated
                                                    moveTo="https://www.virtualbadge.io/terms"
                                                    newTab
                                                    insideTransComponent
                                                >
                                                    {t("linkedin_certificate_generator.labels.terms")}
                                                </LinkTranslated>
                                            ),
                                            privacy_component: (
                                                <LinkTranslated
                                                    moveTo="https://www.virtualbadge.io/privacy-policy"
                                                    newTab
                                                    insideTransComponent
                                                >
                                                    {t("linkedin_certificate_generator.labels.privacy")}
                                                </LinkTranslated>
                                            ),
                                        }}
                                    />
                                </Typography>
                            }
                        />
                        <div className={classes.margin20} />
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div>
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => buttonClick("capture-lead")}
                                    style={{ marginRight: "10px" }}
                                >
                                    {t("linkedin_certificate_generator.buttons.get_access", "Get Link")}
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    onClick={() => buttonClick("sign-up")}
                                >
                                    {submitLoading ? (
                                        <CircularProgress size={24} color="inherit" />
                                    ) : (
                                        <>
                                            {t(
                                                "linkedin_certificate_generator.buttons.sign_up",
                                                "Start Issuing Certificates for Free"
                                            )}
                                        </>
                                    )}
                                </Button>
                            </div>
                        </div>
                        <div className={classes.margin10} />
                    </div>
                </div>
            ) : (
                <div className={classes.containerWrap}>
                    <div className={classes.form}>
                        <h3 className={classes.h3}>
                            {t("linkedin_certificate_generator.texts.instruction_header_1")}
                            <b>{t("linkedin_certificate_generator.texts.instruction_header_2")}</b>
                            {t("linkedin_certificate_generator.texts.instruction_header_3")}:
                        </h3>
                        <TextField
                            className={classes.input}
                            variant="outlined"
                            size="small"
                            label={t("linkedin_certificate_generator.labels.certificate_name")}
                            required
                            value={certName}
                            onChange={(e) => {
                                setCertName(e.target.value);
                            }}
                        />
                        <TextField
                            className={classes.input}
                            variant="outlined"
                            size="small"
                            label={t("linkedin_certificate_generator.labels.organisation_id")}
                            onChange={(e) => {
                                setOrgaId(e.target.value);
                            }}
                        />
                        <TextField
                            className={classes.input}
                            variant="outlined"
                            placeholder={i18n.language === "de" ? "jjjj" : "yyyy"}
                            size="small"
                            label={t("linkedin_certificate_generator.labels.issue_year")}
                            value={issueYear}
                            onChange={(e) => {
                                if (/^[0-9]{0,4}$/g.test(e.target.value)) setIssueYear(e.target.value);
                            }}
                        />

                        <small style={{ textAlign: "right", color: "gray" }}>
                            * {t("linkedin_certificate_generator.texts.mandatory")}
                        </small>
                        <div className={classes.margin20} />
                        <Button type="submit" color="primary" variant="contained" onClick={updateLink}>
                            {t("linkedin_certificate_generator.buttons.generate_link")}
                        </Button>
                        <div className={classes.divider} />
                        <h3 className={clsx(classes.h3, "text-center")}>
                            {" "}
                            {t("linkedin_certificate_generator.texts.link_for_your_participants")}:
                        </h3>
                        {/* TODO: add pseudo loading spinner for 1s */}
                        <div className={clsx(classes.linkContainer, classes.forceSelect)}>
                            {showSpinner && <LoadingOverlay marginTop={"0px"} noMessage />}
                            <span className={classes.show}>
                                <a target="_blank" href={certLink}>
                                    <b>{certLink}</b>
                                </a>
                            </span>
                        </div>
                        <div className={classes.margin20} />
                        <small className="text-muted">
                            {t("linkedin_certificate_generator.texts.link_explanation")}
                        </small>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LinkedinCertificateLinkGenerator;
