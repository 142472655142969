import { PropsWithChildren } from "react";
import { generateNoPreviewFileName } from "./noPreviewAvailable.helpers";
import { useTranslation } from "react-i18next";
import certificationValidatorSizeHandle from "../utils/styles";
import { useWindowSize } from "../../../../utils/customHooks";

type Props = {
    type: ICertificateDetails["certificate_type"];
    format: ICertificateDetails["badge_format"];
    language: "en" | "de";
    voided: boolean;
    haveCredentialPreviewUrl: boolean;
};

const NoPreviewAvailable = ({
    type,
    format,
    language,
    voided,
    children,
    haveCredentialPreviewUrl,
}: PropsWithChildren<Props>) => {
    const { t } = useTranslation();
    const [windowWidth] = useWindowSize();
    const generatedNoPreviewFileName = generateNoPreviewFileName(type, format, language);
    if (voided && !haveCredentialPreviewUrl) {
        return (
            <img
                src={generatedNoPreviewFileName}
                alt={t("certificate_validator.found.no_preview_available_image_alt")}
                style={{ maxWidth: certificationValidatorSizeHandle(windowWidth).certifPicDivWidth[format] }}
            />
        );
    }

    return <>{children}</>;
};

export default NoPreviewAvailable;
