import badgeEn from "./no_preview_placeholder/badge_en_no_preview_available.svg";
import badgeDe from "./no_preview_placeholder/badge_de_no_preview_available.svg";
import certificateA4LandscapeEn from "./no_preview_placeholder/certificate_a4_landscape_en_no_preview_available.svg";
import certificateA4LandscapeDe from "./no_preview_placeholder/certificate_a4_landscape_de_no_preview_available.svg";
import certificateA4PortraitEn from "./no_preview_placeholder/certificate_a4_portrait_en_no_preview_available.svg";
import certificateA4PortraitDe from "./no_preview_placeholder/certificate_a4_portrait_de_no_preview_available.svg";
import certificateLetterLandscapeEn from "./no_preview_placeholder/certificate_letter_landscape_en_no_preview_available.svg";
import certificateLetterLandscapeDe from "./no_preview_placeholder/certificate_letter_landscape_de_no_preview_available.svg";
import certificateLetterPortraitEn from "./no_preview_placeholder/certificate_letter_portrait_en_no_preview_available.svg";
import certificateLetterPortraitDe from "./no_preview_placeholder/certificate_letter_portrait_de_no_preview_available.svg";
import { TNoPreviewSVGMap } from "./noPreviewAvailable.types";

export const NO_PREVIEW_AVAILABLE_SVG: TNoPreviewSVGMap = {
    badge: {
        en: badgeEn,
        de: badgeDe,
    },
    certificate: {
        a4: {
            landscape: {
                en: certificateA4LandscapeEn,
                de: certificateA4LandscapeDe,
            },
            portrait: {
                en: certificateA4PortraitEn,
                de: certificateA4PortraitDe,
            },
        },
        letter: {
            landscape: {
                en: certificateLetterLandscapeEn,
                de: certificateLetterLandscapeDe,
            },
            portrait: {
                en: certificateLetterPortraitEn,
                de: certificateLetterPortraitDe,
            },
        },
    },
}