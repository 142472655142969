import { Tooltip } from "@material-ui/core";
import ErrorOutlinedIcon from "@material-ui/icons/ErrorOutlined";
import { useTranslation } from "react-i18next";
import useGetTranslatedCredentialType from "../utils/useGetTranslatedCredentialType";

type Props = {
    isVerified: boolean;
    credentialType: "badge" | "generic" | "certificate" | "event";
};

const IssuerVerification = ({ isVerified, credentialType }: Props) => {
    const { t } = useTranslation();
    const getTranslatedCredentialType = useGetTranslatedCredentialType();
    if (isVerified) return null;
    return (
        <Tooltip
        arrow
            title={
                <>
                    <p
                        style={{
                            margin: "0px",
                            fontSize: "10px",
                        }}
                    >
                        {t(
                            "certificate.issuer_not_verified.title_issuer_could_not",
                            "Unverified Issuer — Issuer could not be verified."
                        )}
                    </p>
                    <p
                        style={{
                            margin: "0px",
                            fontSize: "10px",
                        }}
                    >
                        {t(
                            "certificate.issuer_not_verified.title_credential_is_only_valid",
                            "{{credential_type}} is only valid if the Issuer is verified.",
                            {
                                credential_type: getTranslatedCredentialType(credentialType, {
                                    firstLetterUpperCase: true,
                                }),
                            }
                        )}
                    </p>
                </>
            }
            placement="right"
        >
            <ErrorOutlinedIcon color="error" />
        </Tooltip>
    );
};

export default IssuerVerification;
