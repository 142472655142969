import { NO_PREVIEW_AVAILABLE_SVG } from "./noPreviewAvailable.constants";
import { TFormat, TOrientation } from "./noPreviewAvailable.types";

export const generateNoPreviewFileName = (
    type: ICertificateDetails["certificate_type"],
    format: ICertificateDetails["badge_format"],
    language: "en" | "de"
) => {
    if (type !== "certificate") {
        return NO_PREVIEW_AVAILABLE_SVG.badge[language];
    }

    const parsedFormat: TFormat = format.includes("US_LETTER") ? "letter" : "a4";
    const parsedOrientation: TOrientation = format.includes("LANDSCAPE") ? "landscape" : "portrait";

    return NO_PREVIEW_AVAILABLE_SVG.certificate[parsedFormat][parsedOrientation][language];
};